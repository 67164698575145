<template>
  <a-popover
    overlayClassName="popover-filter"
    :getPopupContainer="(trigger) => trigger.parentNode"
  >
    <template #content>
      <slot></slot>
    </template>
    <FeatherIcon
      type="info"
      size="14"
      color="#9299B8"
      class="cursor-pointer ml-1"
      @click="$emit('click')"
    />
  </a-popover>
</template>

<script>
export default {
  emits: ['click'],
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';

.popover-filter {
  max-width: '240px';
  &.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    border-right-color: $gray-800;
    border-bottom-color: $gray-800;
  }
  &.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    border-top-color: $gray-800;
    border-left-color: $gray-800;
  }

  .ant-popover-content {
    margin-left: 10px;
    margin-right: 10px;
  }
  // .ant-popover-arrow {
  //   border-top-color: $gray-800;
  //   border-left-color: $gray-800;
  //   border-right-color: $gray-800;
  //   border-bottom-color: $gray-800;
  // }
  .ant-popover-inner {
    background-color: $gray-800;

    padding: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    .ant-popover-inner-content {
      padding: 0;
      color: #fff;
    }
  }
}
</style>
