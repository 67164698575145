<template>
  <div>
    <div class="description">The available operators are:</div>
    <div>
      <table class="w-100">
        <thead class="table-head">
          <tr>
            <th>Operator</th>
            <th>Finds Messages...</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>"happy hour"</td>
            <td>containing the exact phrase "happy hour".</td>
          </tr>
          <tr>
            <td>#insightERA</td>
            <td>containing the hashtag "insightERA".</td>
          </tr>
          <tr>
            <td>@InsighteraTH</td>
            <td>sent from user account "InsighteraTH".</td>
          </tr>
          <tr>
            <td>cat AND dog</td>
            <td>containing both "cat" and "dog".</td>
          </tr>
          <tr>
            <td>cat OR dog</td>
            <td>containing either "cat" or "dog" (or both).</td>
          </tr>
          <tr>
            <td>-"scb"</td>
            <td>not containing "scb"</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvanceSearchOperator',
};
</script>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';
.table-head {
  background-color: $gray-100;
}

tr {
  border-bottom: 1px solid $gray-100;
}
th {
  font-weight: 500;
  color: $gray-800;
}

td {
  color: $gray-600;
}

th,
td {
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.description {
  color: $gray-600;
  padding-bottom: 16px;
}
.advance-search-operator {
  :deep(.ant-modal-title) {
    color: $gray-800;
  }
}
</style>
