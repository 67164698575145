<template>
  <a-card class="channel-overview no-body-padding" title="Channel Performance">
    <template v-if="!isMobileScreen" #extra>
      <div id="level-select">
        <a-select
          v-model:value="categoryLevel"
          :get-popup-container="(trigger) => trigger.parentNode"
          style="width: 150px"
          @change="handleChangeCategoryLevel"
          :disabled="noCategoryData"
          :placeholder="`No Category`"
        >
          <a-select-option
            v-for="cat in categoryData"
            :key="cat.level"
            :value="cat.level"
          >
            {{ cat.name }}
          </a-select-option>
        </a-select>
      </div>
    </template>
    <template v-else #extra>
      <a-dropdown
        placement="bottomRight"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <FeatherIcon class="more-menu" type="more-horizontal" size="16" />
        <template #overlay>
          <a-menu
            id="mode-menu-mobile"
            style="width: 150px"
            class="mode-menu-mobile-2"
            :get-popup-container="(trigger) => trigger.parentNode"
            @click="handleChangeCategoryLevel"
            :placeholder="`No Category`"
          >
            <a-menu-item-group title="SELECT CATEGORY:">
              <a-menu-item
                v-for="cat in categoryData"
                :key="cat.level"
                :value="cat.level"
                :class="{ active: cat.level === categoryLevel }"
              >
                {{ cat.name }}
              </a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
      </a-dropdown>
    </template>

    <div v-if="loading" class="loading-wrapper">
      <a-skeleton :loading="loading" active />
    </div>
    <SourceSelection
      v-if="!loading && isMobileScreen"
      :sourceSelected="selectedSource[0]"
      @onSourceSelect="onSourceSelect"
      :noData="!loading && noData"
    ></SourceSelection>
    <div v-if="!loading && noData" class="no-data-wrapper">
      <NoResult></NoResult>
    </div>
    <!-- TABLE SOV -->
    <div
      v-else-if="!loading && channelData && channelData.length"
      class="channel-table-wrapper"
    >
      <div class="channel-table">
        <a-table
          :data-source="channelData"
          :columns="isMobileScreen ? channelMobileColumns : channelColumns"
          :pagination="false"
          :scroll="{ x: !isMobileScreen ? 'calc(700px + 50%)' : 0, y: 550 }"
        >
          <template #customCategoryTitle="">
            <span class="category-title">Share of Voice (SOV)</span>
          </template>

          <template #category="{ text, index }">
            <a-tooltip :title="text" placement="bottom">
              <div class="badge-wrapper">
                <a-badge :color="getColor(text, index)" />
                <span class="category-text"> {{ text }} </span>
              </div>
            </a-tooltip>
          </template>

          <template #customFacebookTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/facebook.svg')"
              alt="logo"
            />
          </template>

          <template #customTwitterTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/twitter.svg')"
              alt="logo"
            />
          </template>
          <template #customInstagramTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/instagram.svg')"
              alt="logo"
            />
          </template>
          <template #customYoutubeTitle="">
            <img
              class="source-img youtube-img"
              :src="
                require('@/assets/images/source-icon/svg/Youtube-Large.svg')
              "
              alt="logo"
            />
          </template>
          <template #customPantipTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/pantip.svg')"
              alt="logo"
            />
          </template>
          <template #customBlockditTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/blockdit-3.svg')"
              alt="logo"
            />
          </template>
          <template #customTiktokTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/tiktok.svg')"
              alt="logo"
            />
          </template>
          <template #customWebsiteTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/website.svg')"
              alt="logo"
            />
          </template>
        </a-table>
      </div>
    </div>
  </a-card>
</template>
<script>
import { toRefs, watch, ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import NoResult from '@/components/Error/NoResult.vue';
import api from '@/services/api';
import helper from '@/services/helper';
import SourceSelection from '@/components/Selections/SourceSelection.vue';

export default {
  name: 'ChannelPerformance',

  components: {
    NoResult,
    SourceSelection,
  },

  props: {
    mode: String,
    filterResult: Object,
  },

  setup(props) {
    const store = useStore();
    const { state } = store;
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const categoryData = computed(() => state.config.categoryData);
    const brand = computed(() => store.state.account.brand);
    const { filterResult, mode } = toRefs(props);
    let loading = ref(false);
    let categoryLevel = ref(null);
    const allSrcList = api.getAllSource();
    const srcOpt = allSrcList.map((src) => {
      return {
        label: _.capitalize(src),
        value: src,
      };
    });
    const sourceOptions = ref(srcOpt);
    const selectedSource = ref([...allSrcList]);
    const onSourceSelect = (e) => {
      initMobile(e);
    };
    const channelDataAll = ref([]);
    const channelData = ref([]);
    const channelDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    // TABLE SOV
    const channelColumnDefaults = [
      {
        // title: 'Share of Voice (SOV)',
        dataIndex: 'category',
        key: 'category',
        fixed: 'left',
        width: 200,
        slots: { title: 'customCategoryTitle', customRender: 'category' },
      },
    ];
    const channelColumnInfos = [
      {
        // title: 'Facebook',
        dataIndex: 'facebook',
        key: 'facebook',
        align: 'center',
        slots: { title: 'customFacebookTitle' },
        customRender: ({ text, record }) => {
          if (record && record.facebook.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Twitter',
        dataIndex: 'twitter',
        key: 'twitter',
        align: 'center',
        slots: { title: 'customTwitterTitle', customRender: 'twitter' },
        customRender: ({ text, record }) => {
          if (record && record.twitter.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Instagram',
        dataIndex: 'instagram',
        key: 'instagram',
        align: 'center',
        slots: { title: 'customInstagramTitle', customRender: 'instagram' },
        customRender: ({ text, record }) => {
          if (record && record.instagram.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Youtube',
        dataIndex: 'youtube',
        key: 'youtube',
        align: 'center',
        slots: { title: 'customYoutubeTitle', customRender: 'youtube' },
        customRender: ({ text, record }) => {
          if (record && record.youtube.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Pantip',
        dataIndex: 'pantip',
        key: 'pantip',
        align: 'center',
        slots: { title: 'customPantipTitle', customRender: 'pantip' },
        customRender: ({ text, record }) => {
          if (record && record.pantip.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Blockdit',
        dataIndex: 'blockdit',
        key: 'blockdit',
        align: 'center',
        slots: { title: 'customBlockditTitle', customRender: 'blockdit' },
        customRender: ({ text, record }) => {
          if (record && record.blockdit.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Tiktok',
        dataIndex: 'tiktok',
        key: 'tiktok',
        align: 'center',
        slots: { title: 'customTiktokTitle', customRender: 'tiktok' },
        customRender: ({ text, record }) => {
          if (record && record.tiktok.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Website',
        dataIndex: 'website',
        slots: { title: 'customWebsiteTitle', customRender: 'website' },
        key: 'website',
        align: 'center',
        customRender: ({ text, record }) => {
          if (record && record.website.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
    ];
    const channelColumns = channelColumnDefaults.concat(
      allSrcList.map((source) =>
        channelColumnInfos.find((v) => v.key === source)
      )
    );
    let channelMobileColumns = ref([]);

    const noCategoryData = computed(() => {
      return Object.keys(categoryData.value).length === 0;
    });

    const noData = computed(() => {
      for (let item of channelData.value) {
        for (let key in item) {
          if (key !== 'type' && item[key].value !== 0) {
            return false;
          }
        }
      }
      return true;
    });

    const getColor = (catKey, index) => {
      let color;
      let result;
      if (
        categoryByLevel.value &&
        categoryData.value &&
        categoryData.value[categoryByLevel.value.level] &&
        categoryData.value[categoryByLevel.value.level].categories
      ) {
        const categories =
          categoryData.value[categoryByLevel.value.level].categories;
        result = categories.find(
          (c) => c.visible && c.category === catKey.toLowerCase()
        );
      }
      color = result && result.color ? result.color : null;

      if (!color) {
        color = helper.getColor(catKey, index);
      }
      return color;
    };

    const convertValue = (value) => {
      if (value) {
        return helper.numeral(value);
      }
      return 0;
    };

    const initMobile = (value) => {
      const countList = [];
      const engageList = [];
      const viewList = [];
      channelMobileColumns.value = [];
      channelMobileColumns.value.push(channelColumns[0]);
      for (let column of channelColumns) {
        if (column.key === value) {
          channelMobileColumns.value.push(column);
        }
      }
      let obj;
      for (let categoryKey in channelDataAll.value) {
        if (mode.value === 'engagement') {
          obj = {
            category: channelDataAll.value[categoryKey].category,
            [value]: {
              value: channelDataAll.value[categoryKey][value].engagement,
              isHighest:
                channelDataAll.value[categoryKey][value].highestEngagement,
            },
          };
          engageList.push(obj);
        } else if (mode.value === 'mention') {
          obj = {
            category: channelDataAll.value[categoryKey].category,
            [value]: {
              value: channelDataAll.value[categoryKey][value].mention,
              isHighest:
                channelDataAll.value[categoryKey][value].highestMention,
            },
          };
          countList.push(obj);
        } else if (mode.value === 'view') {
          obj = {
            category: channelDataAll.value[categoryKey].category,
            [value]: {
              value: channelDataAll.value[categoryKey][value].view,
              isHighest: channelDataAll.value[categoryKey][value].highestView,
            },
          };
          viewList.push(obj);
        }
      }
      channelDataByMode.engagement = engageList;
      channelDataByMode.mention = countList;
      channelDataByMode.view = viewList;

      channelData.value = channelDataByMode[mode.value];
    };

    const initDesktop = () => {
      const countList = [];
      const engageList = [];
      const viewList = [];
      let obj;
      for (let categoryKey in channelDataAll.value) {
        if (mode.value === 'engagement') {
          obj = {
            category: channelDataAll.value[categoryKey].category,
            facebook: {
              value: channelDataAll.value[categoryKey]['facebook'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['facebook'].highestEngagement,
            },
            twitter: {
              value: channelDataAll.value[categoryKey]['twitter'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['twitter'].highestEngagement,
            },
            instagram: {
              value: channelDataAll.value[categoryKey]['instagram'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['instagram']
                  .highestEngagement,
            },
            youtube: {
              value: channelDataAll.value[categoryKey]['youtube'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['youtube'].highestEngagement,
            },
            pantip: {
              value: channelDataAll.value[categoryKey]['pantip'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['pantip'].highestEngagement,
            },
            blockdit: {
              value: channelDataAll.value[categoryKey]['blockdit'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['blockdit'].highestEngagement,
            },
            tiktok: {
              value: channelDataAll.value[categoryKey]['tiktok'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['tiktok'].highestEngagement,
            },
            website: {
              value: channelDataAll.value[categoryKey]['website'].engagement,
              isHighest:
                channelDataAll.value[categoryKey]['website'].highestEngagement,
            },
          };
          engageList.push(obj);
        } else if (mode.value === 'mention') {
          obj = {
            category: channelDataAll.value[categoryKey].category,
            facebook: {
              value: channelDataAll.value[categoryKey]['facebook'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['facebook'].highestMention,
            },
            twitter: {
              value: channelDataAll.value[categoryKey]['twitter'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['twitter'].highestMention,
            },
            instagram: {
              value: channelDataAll.value[categoryKey]['instagram'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['instagram'].highestMention,
            },
            youtube: {
              value: channelDataAll.value[categoryKey]['youtube'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['youtube'].highestMention,
            },
            pantip: {
              value: channelDataAll.value[categoryKey]['pantip'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['pantip'].highestMention,
            },
            blockdit: {
              value: channelDataAll.value[categoryKey]['blockdit'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['blockdit'].highestMention,
            },
            tiktok: {
              value: channelDataAll.value[categoryKey]['tiktok'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['tiktok'].highestMention,
            },
            website: {
              value: channelDataAll.value[categoryKey]['website'].mention,
              isHighest:
                channelDataAll.value[categoryKey]['website'].highestMention,
            },
          };
          countList.push(obj);
        } else if (mode.value === 'view') {
          obj = {
            category: channelDataAll.value[categoryKey].category,
            facebook: {
              value: channelDataAll.value[categoryKey]['facebook'].view,
              isHighest:
                channelDataAll.value[categoryKey]['facebook'].highestView,
            },
            twitter: {
              value: channelDataAll.value[categoryKey]['twitter'].view,
              isHighest:
                channelDataAll.value[categoryKey]['twitter'].highestView,
            },
            instagram: {
              value: channelDataAll.value[categoryKey]['instagram'].view,
              isHighest:
                channelDataAll.value[categoryKey]['instagram'].highestView,
            },
            youtube: {
              value: channelDataAll.value[categoryKey]['youtube'].view,
              isHighest:
                channelDataAll.value[categoryKey]['youtube'].highestView,
            },
            pantip: {
              value: channelDataAll.value[categoryKey]['pantip'].view,
              isHighest:
                channelDataAll.value[categoryKey]['pantip'].highestView,
            },
            blockdit: {
              value: channelDataAll.value[categoryKey]['blockdit'].view,
              isHighest:
                channelDataAll.value[categoryKey]['blockdit'].highestView,
            },
            tiktok: {
              value: channelDataAll.value[categoryKey]['tiktok'].view,
              isHighest:
                channelDataAll.value[categoryKey]['tiktok'].highestView,
            },
            website: {
              value: channelDataAll.value[categoryKey]['website'].view,
              isHighest:
                channelDataAll.value[categoryKey]['website'].highestView,
            },
          };
          viewList.push(obj);
        }
      }
      channelDataByMode.engagement = engageList;
      channelDataByMode.mention = countList;
      channelDataByMode.view = viewList;

      channelData.value = channelDataByMode[mode.value];
    };

    const init = async (fv) => {
      loading.value = true;
      if (fv) {
        const result = await api.getCategorySOV(
          fv,
          categoryLevel.value
        );
        if (result.message.data) {
          channelDataAll.value = result.message.data;
          if (isMobileScreen.value) {
            const sources = selectedSource.value[0];
            initMobile(sources);
          } else {
            initDesktop();
          }
        }
      }
      loading.value = false;
    };

    const handleChangeCategoryLevel = (level) => {
      if (isMobileScreen.value === true) {
        level = level.item.value;
      }
      categoryLevel.value = level;
      return categoryLevel.value;
    };

    const categoryByLevel = computed(() => {
      return categoryData.value[categoryLevel.value];
    });

    watch(
      () => filterResult.value,
      () => {
        const srcList = filterResult.value.source;
        // console.log('WTF', filterResult.value.level);

        if (srcList) {
          if (srcList.length > 0) {
            sourceOptions.value = srcList.map((src) => {
              return {
                label: _.capitalize(src),
                value: src,
              };
            });
            selectedSource.value = [...srcList];
          } else if (srcList.length === 0) {
            selectedSource.value = [...allSrcList];
          }
          init(filterResult.value);
        }
      }
    );

    watch(
      () => mode.value,
      () => {
        if (!isMobileScreen.value) {
          selectedSource.value = [...allSrcList];
          initDesktop();
        } else {
          const sources = selectedSource.value[0];
          initMobile(sources);
        }
      }
    );

    watch(
      () => categoryLevel.value,
      () => {
        const srcList = filterResult.value.source;
        // console.log('WTF', filterResult.value.level);

        if (srcList) {
          if (srcList.length > 0) {
            sourceOptions.value = srcList.map((src) => {
              return {
                label: _.capitalize(src),
                value: src,
              };
            });
            selectedSource.value = [...srcList];
          } else if (srcList.length === 0) {
            selectedSource.value = [...allSrcList];
          }
          init(filterResult.value, brand.value, categoryLevel.value);
        }
      }
    );

    watch(
      () => isMobileScreen.value,
      () => {
        if (!isMobileScreen.value) {
          selectedSource.value = [...allSrcList];
          initDesktop();
        } else {
          const sources = selectedSource.value[0];
          initMobile(sources);
        }
      }
    );

    onMounted(() => {
      const srcList = filterResult.value.source;
      // console.log('WTF', filterResult.value.level);

      if (srcList) {
        if (srcList.length > 0) {
          sourceOptions.value = srcList.map((src) => {
            return {
              label: _.capitalize(src),
              value: src,
            };
          });
          selectedSource.value = [...srcList];
        } else if (srcList.length === 0) {
          selectedSource.value = [...allSrcList];
        }
        // filterResult.value.category = [];
        // filterResult.value.subCategory = [];
        init(filterResult.value);
      }

      if (categoryData.value.length > 0) {
        categoryLevel.value = 0;
      }
    });

    return {
      loading,
      sourceOptions,
      selectedSource,
      isMobileScreen,
      channelData,
      getColor,
      convertValue,
      channelColumns,
      onSourceSelect,
      channelDataAll,
      channelMobileColumns,
      categoryByLevel,
      categoryLevel,
      handleChangeCategoryLevel,
      categoryData,
      noData,
      noCategoryData,
    };
  },
};
</script>
<style lang="scss">
.mode-menu-mobile-2 {
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none !important;
  }
  .ant-select-selector {
    border: none !important;
  }

  &.ant-select-focused {
    border: none !important;
  }
}

.channel-overview {
  .source-img {
    width: 28px;
    height: 28px;
  }
  .loading-wrapper {
    min-height: 400px;
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .chart-wrapper {
    width: 100%;
    min-height: 400px;
    .chart {
      height: 400px;
    }
  }
  .source-selector {
    padding: 24px;
  }
  .source-selector-mobile {
    .source-select-title {
      font-weight: 500;
      line-height: 24px;
      color: #272b41;
      margin-bottom: 5px;
    }
    .source-select {
      .ant-select-selector {
        border: 1px solid #e3e6ef;
        border-radius: 4px;
        min-height: 42px !important;
        .ant-select-selection-search input {
          height: 42px;
        }
        .ant-select-selection-placeholder {
          line-height: 40px !important;
        }
      }
      .source-item {
        display: flex;
        align-items: center;
        .source-img {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
  .no-data-wrapper {
    min-height: 400px;
    padding: 20px;
  }
  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .expand-sov-table {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0;
    .expand-text {
      color: #097cff;
      font-size: 12px;
    }
  }
  // TABLE CSS
  .channel-table-wrapper {
    padding: 0;
    .channel-table {
      .ant-table-thead > tr > th {
        background: #fff !important;
        border-top: 1px solid #f0f0f0 !important;
      }

      .ant-table-thead > tr > th:first-child,td:first-child {
        border-left: 1px solid #f0f0f0 !important;
        width:160px;
      }

      .ant-table-thead > tr > th:last-child {
        border-right: 1px solid #f0f0f0 !important;
      }

      .ant-table tbody > tr > td:first-child {
        border-left: 1px solid #f1f2f6;
      }

      .ant-table tbody > tr > td:last-child {
        border-right: 1px solid #f0f0f0 !important;
      }

      .category-title {
        font-weight: 700;
        font-size: 16px;
        color: #5a5f7d;
      }

      .badge-wrapper {
        min-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        transform: translateX(0px);
        text-overflow: ellipsis;
        .category-text {
          padding-left: 8px;
          color: #5a5f7d;
          overflow: hidden;
          text-transform: capitalize;
          text-overflow: ellipsis;
        }
      }
      @media screen and (max-width: 575px) {
        .badge-wrapper {
          min-width: 100px;
        }
      }

      .source-value {
        &.highlight-color {
          background: #fff;
          // background: #def7ef;
        }
      }

      .category-data {
        color: #5a5f7d;
      }
      .youtube-img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .source-selector-mobile {
    margin-bottom: 20px;
    .source-select-title {
      font-weight: 500;
      line-height: 24px;
      color: #272b41;
      margin-bottom: 5px;
    }
    .source-select {
      .ant-select-selector {
        border: 1px solid #e3e6ef;
        border-radius: 4px;
        min-height: 42px !important;
        .ant-select-selection-search input {
          height: 42px;
        }
        .ant-select-selection-placeholder {
          line-height: 40px !important;
        }
      }
      .source-item {
        display: flex;
        align-items: center;
        .source-img {
          height: 16px;
          width: 16px;
        }
      }
    }
    .ant-select-selection-item {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .fa-stack {
      height: 13px;
      line-height: 13px;
      position: relative;
      vertical-align: middle;
      width: 13px;
      font-size: 13px;
      margin-right: 8px;
      .fa-circle {
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: 13px;
      }
      .icon-social {
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: 8px;
        color: #fff;
        padding: 3px 0;
      }
      .icon-twitter {
        color: #1da1f2;
      }
      .icon-instagram {
        color: #dd2a7b;
      }
      .icon-youtube {
        color: #e32212;
      }
      .icon-pantip {
        color: #7459c8;
      }
      .icon-website {
        color: #252525;
      }
    }
    .icon-facebook {
      color: #1977f2;
      margin-right: 8px;
    }
    .source-img {
      height: 13px;
      width: 13px;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 575px) {
  .channel-overview {
    .channel-table-wrapper {
      padding: 0 !important;
    }
    .social-select-list {
      display: block !important;
    }
    .ant-table-header {
      overflow: unset !important;
    }
  }
}
</style>
<style scoped>
@media only screen and (max-width: 767px) {
  :deep(.ant-card-body) {
    padding: 0 !important;
  }
}

@media screen and (max-width: 575px) {
  :deep(.ant-card-body) {
    padding: 20px !important;
  }
}
</style>
